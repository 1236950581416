<div class="modal-container" [ngClass]="{ active: isOpen }">
  <div class="modal at-shadow-mega-large">
    <div class="at-d-flex at-justify-between at-align-items-center at-mb-3">
      @if (title) {
        <h2 class="at-header2 at-fw-500">{{ title }}</h2>
      }
      @if (hasCloseIcon) {
        <button type="button" (click)="closeModal()">
          <img src="assets/images/svg/close.svg" alt="close" />
        </button>
      }
    </div>
    @if (description) {
      <p class="at-fw-400 at-line-height-150">
        {{ description }}
      </p>
    }

    @if (customizeContent) {
      <ng-container [ngTemplateOutlet]="customizeContent" />
    }
  </div>
</div>

<div
  class="overlay"
  (click)="closeModal()"
  [ngClass]="{ active: isOpen }"></div>
